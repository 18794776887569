:root {
  --bmw-m-light-sky-blue: #81c4ff;
  --bmw-m-yale-blue: #16588e;
  --bmw-m-alizarin-crimson: #e7222e;

  --bs-font-sans-serif: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --bs-dark: #444;
  --bs-light: #ccc;
  --bs-light-grey: #f9fafb;
  --bs-secondary: #777;
  --bs-success: #009688;
  /* --bs-danger: #E7222E; */
  --bs-danger: tomato;
  --title-color: #93a2bf;
  /* --title-color: tomato; */

  --light-divider-color: #eee;
  --light-grey: #e1e3e8;
  --overview-title-color: #93a2bf;

  --link-color: #3473a0;
  --link-hover-color: #23527c;
  --link-active-color: #16588e;

  --navbar-dark: #333;
  --navbar-light: #ccc;
  --navbar-height: 40px;
  --navbar-hover: #eee;

  --sidebar-dark: #444;
  --sidebar-light: rgb(250, 250, 250);
  --sidebar-width: 250px;
  --sidebar-highlight: var(--light-grey);
  --sidebar-divider: #eee;
  --sidebar-hover: var(--overview-title-color);

  --portal-info-background: #f5f5f5;
  --portal-info: var(--bs-info);
  --portal-neutral: grey;
  --portal-success: var(--bs-success);
  --portal-success-hover: #017468;
  --portal-warning: var(--bs-warning);
  --portal-danger: var(--bs-danger);

  --dt-input-border: #ccc;
  --dt-input: var(--bs-dark);
  --dt-row-details: whitesmoke;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.w-8 {
  width: 8% !important;
}

.w-10 {
  width: 10% !important;
}

.overflow-x-scroll {
  overflow-x: auto;
}

.toast-container.portal {
  right: 0 !important;
  bottom: 0 !important;
  padding-bottom: 50px;
  padding-right: 10px;
  position: fixed !important;
  z-index: 50;
}

.toast {
  font-size: 1rem;
}

.portal-neutral {
  color: var(--portal-neutral);
  font-weight: bold;
}

.portal-info {
  color: var(--portal-info);
  font-weight: bold;
}

.portal-success {
  color: var(--portal-success);
  font-weight: bold;
}

td.portal-success-background {
  background-color: rgba(0, 150, 136, 0.2);
}

.portal-warning {
  color: var(--portal-warning);
  font-weight: bold;
}

td.portal-warning-background {
  background-color: rgba(255, 193, 7, 0.2);
}

.portal-danger {
  color: var(--portal-danger);
  font-weight: bold;
}

td.portal-danger-background {
  background-color: rgba(255, 99, 71, 0.2);
}

.swal-button--danger {
  background-color: var(--portal-danger);
}

.swal-wide {
  width: 1200px !important;
}

.swal-content {
  overflow-wrap: break-word;
}

.swal-textarea {
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--bs-light);
  margin: 1rem 0 0;
  padding: 0.5rem 0.8rem;
}

.row-inactive {
  color: var(--bs-light);
}

.row-deleted {
  color: var(--bs-light);
  text-decoration: line-through;
}

.modal-content {
  min-width: 600px;
  margin: 30px auto;
  border-radius: 6px;
}

.modal-subheadline {
  margin: 0 1.5rem 3rem;
}

.quick-links-configuration-modal .modal-subheadline {
  margin: 0 1.5rem 2rem;
}

#sharedModalDialog[data-type='custom-modal'] .modal-header {
  border-bottom: none;
  padding: 1.5rem 1.5rem 0.5rem;
}

#sharedModalDialog[data-type='custom-modal'] .modal-content {
  overflow: visible;
  border-radius: 4px;
}

#sharedModalDialog[data-type='custom-modal'] .modal-content .modal-body {
  overflow-x: visible;
  overflow-y: auto;
  padding: 0 0 3.5rem;
}

.quick-links-configuration-modal #sharedModalDialog[data-type='custom-modal'] .modal-content .modal-body {
  padding: 0 0 2rem;
}

#sharedModalDialog[data-type='custom-modal'] .modal-content .modal-footer {
  padding: 1.1rem 1.5rem;
}

.modal-data-table-row {
  margin: 2px 0;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid #efefef;
}

.modal-data-table-row.modal-data-table-row--last-row {
  border-bottom: none;
}

.modal-connectivity-test {
  margin: 2.5rem 1rem 0;
}

.modal-connectivity-test-text .swal-text {
  text-align: center;
}

.text-array-field:not(:last-child) {
  margin-bottom: 0.4rem;
}

.accordion-header button.accordion-button:not(.collapsed) {
  background-color: transparent;
  border: 1px solid #e0e0e0;
  border-radius: 3px 3px 0 0;
  margin-bottom: 0;
  box-shadow: none;
}

.accordion-header > h2 {
  margin-bottom: 0;
}

.accordion-header button {
  font-weight: bold;
  overflow-x: auto;
}

.accordion-header button.accordion-button.collapsed {
  background-color: transparent;
  border: 1px solid #d0d0d0;
  border-radius: 3px;
  box-shadow: none;
  margin-bottom: 0.5rem;
}

div.accordion-item {
  border: 0;
  min-width: 100%;
}

.accordion-item div.accordion-collapse {
  border: solid #f0f0f0;
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 3px 3px;
  margin-bottom: 0.5rem;
}

.accordion-button-label {
  position: relative;
}

.accordion-button-datetime {
  padding-right: 2rem;
}

.connectivity-result-container {
  border-radius: 3px;
  background-color: white;
  padding: 1rem 0;
  overflow-x: hidden;
}

span > button.connectivity-result-inline-button {
  position: absolute;
  top: 50%;
  left: calc(100% + 0.6rem);
  transform: translateY(-50%);
  padding: 2px 8px;
  line-height: 1.8rem;
  border-radius: 3px;
}

.connectivity-test-pagination-container {
  padding: 1.5rem 2rem;
}

.connectivity-test-pagination-container .page-item {
  cursor: pointer;
}

div.invalid-feedback,
div.valid-feedback {
  font-size: 1rem;
}

button.btn.btn-select {
  padding-top: 0rem;
  padding-bottom: 0rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
  background-color: #fff;
}

hr.portal,
hr.collapse {
  color: var(--light-divider-color);
  opacity: 1;
  margin-top: 0.25rem;
}

.link {
  color: var(--link-color);
}
.link:hover {
  color: var(--link-hover-color);
}

.icon-color {
  color: var(--link-color);
}

html {
  /* This defines how much 1 rem is */
  font-size: 12px;
}

/* GENERAL DESIGN */

.page-header {
  /* padding-top: 1.75rem; */
  padding-bottom: 0.75rem;
  border-bottom: 1px solid var(--light-divider-color);
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.8rem;
}

h1.page-header {
  color: var(--bs-dark);
  font-family: Arial, sans-serif;
  font-weight: bold;
}

h1 small {
  font-size: 65%;
  font-weight: 400;
}

h2 small,
h3 small,
h4 small,
h5 small {
  font-weight: 400;
  line-height: 1;
  font-size: 75%;
  color: #777;
}

h3 {
  font-weight: bold;
}

h3.overview {
  color: var(--overview-title-color);
  font-size: 1.833rem;
  margin-top: 0;
}

h5.headline {
  color: var(--overview-title-color);
}

select.capitalize-options,
select.capitalize-options option {
  text-transform: capitalize;
}

.h4line {
  color: #c4c4c4;
  text-align: center;
  border-bottom: 1px solid #c4c4c4;
  line-height: 0.1em;
  margin: 2rem 0 2rem;
  font-size: 1.2rem;
}

.h4line > span {
  padding: 0 0.83333rem;
  background: white;
}

ul.overview {
  list-style-type: none;
}

a.overview.btn {
  padding: 0.16667rem 1rem;
  color: var(--bs-dark);
}

a.overview.btn:focus,
a.overview.btn:hover {
  text-decoration: underline;
  text-decoration-color: var(--overview-title-color);
  text-decoration-style: solid;
}

a {
  text-decoration: none;
  color: var(--link-color);
}

a:hover {
  color: var(--link-hover-color);
}

a:not(.dropdown-item, .nav-link, .navbar-brand):hover {
  text-decoration: underline;
}

.btn-group:not(:last-child) {
  margin-right: 0.33rem;
}

.btn {
  font-size: 1.16rem;
  padding: 0.5rem 1rem;
  white-space: nowrap;
}

.btn-light-grey {
  background-color: var(--light-grey);
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.btn-dark {
  background-color: var(--bs-dark);
}

.btn-success {
  background-color: var(--portal-success);
  border-color: var(--portal-success);
  color: var(--bs-white);
}

.btn-success:hover {
  background-color: var(--portal-success-hover);
  border-color: var(--portal-success-hover);
  color: var(--bs-white);
}

.btn-outline-dark {
  color: var(--bs-dark);
  border-color: var(--bs-dark);
}

.btn-sm.loading-animation {
  pointer-events: none;
  background-color: var(--overview-title-color);
  border-color: var(--overview-title-color);
  color: var(--bs-white);
}

.btn-sm .spinner-border {
  display: none;
}

.btn-sm.loading-animation .spinner-border {
  display: inline-block;
  margin-right: 6px;
}

.btn.loading-animation {
  pointer-events: none;
  background-color: var(--overview-title-color);
  border-color: var(--overview-title-color);
  color: var(--bs-white);
}

.btn .spinner-border {
  display: none;
}

.btn.loading-animation .spinner-border {
  display: inline-block;
  margin-right: 8px;
}

.btn-sm,
.btn-group-sm > .btn {
  font-size: 1rem;
  color: var(--bs-black);
  background-color: var(--light-grey);
  display: inline-block;
  outline: none;
  background-image: none;
  border: none;
  border-radius: 0;
  padding: 0.66667rem 1.33333rem;
  user-select: none;
}

.btn-sm:hover,
.btn-group-sm > .btn:hover,
.btn-sm:focus,
.btn-group-sm > .btn:focus {
  color: var(--bs-white);
  background-color: var(--overview-title-color);
}

.btn-sm[disabled]:hover {
  color: var(--bs-black);
  background-color: var(--light-grey);
  cursor: not-allowed;
}

.btn-datatable {
  font-size: 1rem;
  color: var(--bs-dark);
  background-color: var(--bs-white);
  display: inline-block;
  outline: none;
  border: 1px solid var(--bs-dark);
  padding: 0.2rem 0.3rem;
  margin: 1px 3px 1px 0;
  min-width: 25px;
  text-align: center;
}

.btn-datatable:hover,
.btn-datatable:focus {
  color: var(--bs-white);
  background-color: var(--overview-title-color);
}

.btn-transparent {
  padding: 0;
}

.btn-transparent:focus {
  box-shadow: none;
}

.btn-transparent:hover {
  background-color: transparent !important;
}

.detail-actions-btn-group {
  gap: 0.3333rem;
}

@media screen and (min-width: 1200px) {
  h1.page-header {
    font-size: 2.5rem;
    margin: 0 0 1.75rem;
  }

  p.overview {
    font-size: 1.08333rem;
  }
}

.m-10 {
  margin: 10px 0;
}

.m-20 {
  margin: 20px 0;
}

.loading-form-inline .spinner-border {
  display: none;
}

.loading-form-inline.loading-animation .spinner-border {
  display: inline-block;
  margin-left: 8px;
}

.badge-primary {
  background-color: var(--bs-primary);
}

.badge-secondary {
  background-color: var(--bs-secondary);
}

.badge-success {
  background-color: var(--bs-success);
}

.badge-warning {
  background-color: var(--bs-warning);
}

.badge-danger {
  background-color: var(--bs-danger);
}

/* BREADCRUMBS */

.breadcrumb li {
  max-width: 100%;
}

li.breadcrumb-item {
  padding: 7px 0;
  font-size: 14px;
  font-weight: bold;
}

li.breadcrumb-item.no-padding {
  padding: 0;
}

.breadcrumb-dropdown,
.breadcrumb-type-select + .dropdown-toggle {
  font-size: 14px;
  font-weight: bold;
  border: none;
}

.breadcrumb-type-select {
  border: none !important;
  background-image: none;
}

.breadcrumb-type-select .dropdown-toggle {
  padding: 7px 0;
  border: none !important;
}

.breadcrumb-type-select .dropdown-toggle:after {
  display: none !important;
}

.compare-vpc-button-container {
  padding: 3px 0;
  flex: 1;
  min-width: 150px;
  text-align: right;
}

#create-vpc-test-button,
#reload-vpc-tests-button {
  margin: 0;
}

.tab-header {
  display: flex;
}

.tab-title-container {
  display: table-cell;
}

.tab-title-container > h4 {
  margin: 5px 0;
}

.tab-button-container {
  flex: 1;
  text-align: right;
}

.tab-button-container > button {
  vertical-align: top;
}

/** NAVBAR **/

.navbar {
  padding: 0 0.833rem;
}

.navbar .btn {
  background-color: transparent;
  border: 0;
  height: var(--navbar-height);
  display: inline-flex;
  align-items: center;
}

.navbar .btn[role='img'] {
  align-self: center;
  margin-right: 0.375rem;
}

.navbar-brand {
  font-family: 'Arial', sans-serif;
  font-weight: 300;
  line-height: 1.67rem !important;
  display: flex;
  align-items: center;
  height: 40px;
}

.navbar-brand > img {
  margin-top: -0.08333rem;
  margin-right: 1.66667rem;
}

.navbar-inverse {
  background-color: var(--navbar-dark);
  border-color: var(--navbar-light);
}

.navbar-inverse .navbar-brand,
.navbar-inverse .btn {
  color: #fff;
}

.navbar-top-links > li > a {
  color: #fff;
}

.navbar.top-links button,
.navbar-top-links a {
  padding: 0.833rem;
  min-height: var(--navbar-height);
}

.navbar-top-links > .dropdown.show > .dropdown-toggle,
.navbar-top-links > .dropdown > .dropdown-toggle:hover,
.navbar-top-links > button:hover,
.navbar-top-links > button:focus,
.navbar-top-links > a:hover,
.navbar-top-links > a:focus {
  background-color: var(--navbar-dark);
}

.dropdown-menu {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.18);
}

.navbar-top-links .dropdown-item {
  color: var(--navbar-dark);
  font-size: 1.16667rem;
  line-height: 1.43;
  padding: 0.25rem 1.66667rem;
  min-height: 0;
}

.no-dropdown-arrow:after {
  display: none;
}

.navbar .username-icon {
  margin-right: 0.5rem;
}

.navbar .display-username {
  font-size: 1rem;
}

.link-icon {
  color: var(--link-color);
}

.link-icon:hover {
  color: var(--link-hover-color);
}

#page-wrapper {
  margin: var(--navbar-height) 0;
}

@media screen and (min-width: 1200px) {
  .navbar-brand {
    font-size: 1.33333rem;
  }
}

/** SIDEBAR **/

.sidebar-menu-title:after {
  align-self: center;
  font-size: 70%;
  margin-left: auto;
  font-family: 'Font Awesome 6 Free', monospace;
  font-weight: 900;
  content: '\f078';
}

.sidebar-menu-title.collapsed:after {
  content: '\f053';
}

.sidebar-menu {
  list-style: none;
  padding-left: 0;
  padding-inline-start: initial;
}

.sidebar > .sidebar-menu > .sidebar-item {
  border-bottom: 1px solid var(--sidebar-divider);
}

.sidebar-item {
  padding: 0;
  position: relative;
  display: block;
}

.sidebar-item > a {
  padding: 0.83333rem 1.25rem;
  display: flex;
  color: var(--sidebar-dark);
  text-decoration: none;
  cursor: pointer;
}

.sidebar-item-level2 > a {
  padding-left: 3rem;
}

.sidebar-item-level3 > a {
  padding-left: 4rem;
}

.sidebar-item > a:hover {
  background-color: var(--sidebar-hover);
  color: #fff;
  text-decoration: none !important;
}

.sidebar-item-icon {
  margin-right: 0.5rem;
  align-self: center;
}

img.sidebar-item-icon {
  align-self: baseline;
  width: 1.333rem;
  color: var(--sidbar-dark);
}

.sidebar-item-active {
  /* border-right: 4px solid var(--sidebar-highlight); */
  background-color: var(--sidebar-highlight);
  font-weight: bold;
}

.sidebar-item .loading-spinner {
  background-color: transparent;
  height: 1.5rem;
  margin-left: auto;
}

#page-content-wrapper {
  padding: 2rem;
}

.sidebar-badge {
  margin-left: 0.5rem;
  padding-top: 5px;
}

.sidebar {
  background-color: var(--sidebar-light);
}

.btn-close-sidebar {
  height: 75px;
  background-color: var(--sidebar-light);
  padding: 0.5rem 0.25rem;
  border-right: solid 1px var(--sidebar-divider);
  border-top: solid 1px var(--sidebar-divider);
  border-bottom: solid 1px var(--sidebar-divider);
  border-radius: 0 2px 2px 0;
}

.btn-close-sidebar:focus {
  box-shadow: inset -2px 0px 7px 0rem var(--sidebar-divider);
}

.sidebar-close {
  margin-left: calc(var(--sidebar-width) - 1px);
  position: fixed;
  bottom: 10%;
}

.sidebar-collapse {
  width: 0px !important;
}

.sidebar-collapse-close,
.sidebar-collapse-main {
  margin-left: 0px !important;
}

/* Extra small devices (phones, up to 767px) */
@media screen and (max-width: 767px) {
  .sidebar {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .sidebar {
    position: fixed;
    width: var(--sidebar-width);
    height: calc(100% - 2 * var(--navbar-height) + 17px);
    overflow-x: scroll;
    border-right: solid 1px var(--sidebar-divider);
  }

  #page-content-wrapper {
    position: static;
    margin-left: var(--sidebar-width);
  }
}

@media screen and (min-width: 1200px) {
  .sidebar-item {
    font-size: 1.083rem;
  }

  .sidebar-item-icon {
    font-size: 1rem;
  }
}

/* NAV TABS */

.nav-tabs > .nav-item {
  cursor: pointer;
}

.nav-tabs > .nav-item > .nav-link {
  color: inherit;
}

.nav-tabs > .nav-item > .nav-link.active {
  font-weight: 700;
  border-bottom: 2px solid var(--bs-primary);
}

.nav-tabs > .nav-item > .nav-link.disabled {
  color: var(--navbar-light);
  pointer-events: none;
  font-style: italic;
}

.nav-tabs .nav-link:not(.active):hover {
  background-color: var(--navbar-hover);
}

/* DASHBOARD */

.dashboard-header {
  display: flex;
  flex-direction: row;
}

.dashboard-title {
  flex: 1;
}

.dashboard-controls {
  height: 100%;
  padding: auto 3px;
}

.dashboard-action {
  padding: 2px 0;
  font-size: 1.1rem;
}

.dashboard-action > a {
  padding: 0 !important;
}

.dashboard-action i {
  margin-right: 2px;
}

.dashboard-card {
  position: relative;
  height: 100%;
  padding: 4px 0;
  background-color: var(--bs-white);
  box-shadow: 0 8px 16px -10px rgba(9, 30, 66, 0.16);
}

.quick-links-card {
  padding: 4px 0;
  background-color: var(--bs-white);
  box-shadow: 0 8px 16px -10px rgba(9, 30, 66, 0.06);
}

.dashboard-metric {
  position: relative;
}

.loading .dashboard-metric:after {
  content: '';
  position: absolute;
  height: 20px;
  left: 8px;
  bottom: 14px;
  background: repeating-linear-gradient(to right, #fff 0%, #f0f0f0 50%, #fff 100%);
  width: 60%;
  background-size: 200% auto;
  background-position: 0 100%;
  animation: loading-gradient 1s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

@keyframes loading-gradient {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.dashboard-card .card-title,
.quick-links-card .card-title {
  font-size: 16px;
}

.dashboard-card .card-dropdown {
  margin: 0 -2px;
}

.dashboard-card .card-text {
  margin-bottom: 0;
  font-size: 0.9rem;
  color: var(--bs-gray);
}

.dashboard-card > .card-footer {
  background-color: transparent;
  padding: 1.1rem 1rem 1rem;
}

.dashboard-card > .card-footer > a {
  font-size: 14px;
  text-decoration: underline;
  color: var(--bs-secondary);
}

.dashboard-card-link {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.3rem 14px 0;
  font-size: 14px;
}

.dashboard-card-link > a {
  color: var(--bmw-m-yale-blue);
}

.dashboard-card-link > a > i {
  color: var(--bmw-m-yale-blue);
  margin-left: 5px;
  font-size: 12px;
}

.dashboard-container-icon {
  position: absolute;
  width: auto;
  top: 16px;
  right: 18px;
}

.dashboard-settings-icon {
  display: inline-block;
  margin: 0 8px;
}

.dashboard-card-subtitle {
  font-size: 14px;
  padding: 15px 0 2px;
}

.card-footer-separator {
  margin: 0 9px;
}

#quick-actions-container {
  cursor: pointer;
}

#quick-actions-content.collapse.show ~ .plus-icon,
#quick-actions-content.collapsing:not(.show) ~ .plus-icon {
  display: none;
}

#quick-actions-content.collapse:not(.show) ~ .minus-icon {
  display: none;
}

.config-quick-links-content {
  margin: 0.3rem 1.5rem;
}

#config-quick-links-container {
  min-height: 4rem;
  padding: 8px 14px;
  border: 1px solid #ededed;
  border-radius: 4px;
}

.config-quick-links-item {
  position: relative;
  display: inline-block;
  margin: 0.1rem;
  padding: 0.6rem 0.9rem;
  color: var(--bs-white);
  background-color: var(--bs-primary);
  border-radius: 8px;
  font-size: 1rem;
  line-height: 1rem;
}

.config-quick-links-item i.remove-item {
  font-size: 1rem;
  line-height: 1rem;
  margin-left: 0.7rem;
  cursor: pointer;
}

.config-quick-links-item i.page-icon {
  font-size: 1rem;
  line-height: 1rem;
  margin-right: 0.5rem;
}

#config-quick-links-dropdown a.dropdown-toggle {
  background-color: #f8f8f8;
}

#config-quick-links-dropdown:focus {
  border: none !important;
  outline: none !important;
}

#config-quick-links-dropdown ul.dropdown-menu {
  overflow-x: hidden;
  overflow-y: scroll;
  min-width: 70%;
  max-height: 40vh;
}

#config-quick-links-dropdown .dropdown-item {
  position: relative;
}

#config-quick-links-dropdown .dropdown-item:after {
  content: attr(data-link);
  position: absolute;
  margin: 0.1rem 0.6rem;
  font-size: 0.9rem;
  color: var(--bs-primary);
}

/* CHARTS */

.dashboard-chart {
  margin: 0 0 1.5rem;
}

.dashboard-chart canvas {
  min-height: 270px;
  max-height: 320px;
}

.chart-icon {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 16px;
  cursor: pointer;
}

i.chart-info:hover ~ .chart-info-box {
  display: block;
}

.chart-info-box {
  display: none;
  position: absolute;
  width: 100%;
  transform: translateY(1.3rem);
  font-size: 1rem;
  z-index: 100;
}

#chart-timeframe-select {
  height: 33px;
  padding: 0 3rem 0 1rem;
}

.chart-timeframe-switch {
  background-color: var(--bs-light-grey);
  border-radius: 5px;
  border: 1px solid var(--bs-primary);
}

.chart-timeframe-switch input[type='radio'] {
  display: none;
}

.chart-timeframe-switch input[type='radio']:checked + label {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.chart-timeframe-switch .nav-link {
  cursor: pointer;
}

.chart-timeframe-switch .nav-item:first-child .nav-link {
  border-radius: 4px 0 0 4px;
}

.chart-timeframe-switch .nav-item:last-child .nav-link {
  border-radius: 0 4px 4px 0;
}

.chart-donut-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.4rem;
  font-weight: bold;
}

.dataset-checkbox-container {
  width: auto;
  margin: 0 auto;
  padding: 2rem 13px 2rem 0;
  text-align: center;
}

.dataset-checkbox-container input {
  visibility: hidden;
}

.dataset-checkbox-container input ~ label {
  margin-bottom: 0.8rem;
  padding: 0.3rem 0.6rem;
  border: 1px solid var(--bs-dark);
  cursor: pointer;
}

.dataset-checkbox-container input:disabled ~ label {
  color: var(--bs-light);
  border: 1px solid var(--bs-light);
  cursor: default;
}

.dataset-checkbox-container input:checked ~ label {
  font-weight: bold;
  color: var(--bs-primary);
  border: 1px solid var(--bs-primary);
}

.connectivity-donut-chart,
.donut-chart {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 180px;
  border-radius: 50%;
}

.donut-chart-zoom {
  background: transparent;
  position: absolute;
  left: 50%;
  top: 50%;
  transform-origin: center;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.donut-hole {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background: #fff;
  z-index: 1;
}

.donut-title {
  position: absolute;
  font-size: 1.6rem;
  font-weight: bold;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

/* DATA TABLES: GENERAL */

table.dataTable tbody td {
  vertical-align: middle;
  padding: 0.66667rem 0.66667rem 0.66667rem 1.5rem;
}

.dataTables_processing > div {
  opacity: 0;
}

.dataTables_wrapper {
  margin: 0 auto;
  position: relative;
  clear: both;
}

th,
td {
  overflow: hidden;
}

.table > :not(caption) > * > * {
  border-bottom-width: 0;
}

.dataTables_wrapper .dataTables_column_selector {
  float: right;
  text-align: right;
  margin-left: 5px;
  border: 1px solid var(--dt-input-border);
  border-radius: 3px;
  background-color: transparent;
  display: flex;
}

@media screen and (max-width: 640px) {
  .dataTables_wrapper .dataTables_column_selector {
    float: none;
    text-align: center;
    margin-top: 0.5em;
  }
}

.dataTables_wrapper .dataTables_column_selector .btn-small {
  font-size: 9pt;
  color: var(--dt-input);
  padding: 4px 5px;
  background-color: transparent;
}

.dataTables_wrapper .dataTables_column_selector div.dropdown button {
  font-size: 9pt;
  color: var(--dt-input);
  padding: 4px 12px;
  background-color: transparent;
}

.dataTables_wrapper .dataTables_filter input {
  font-size: 9pt;
  border: 1px solid var(--dt-input-border);
  border-radius: 3px;
  padding: 5px;
  background-color: transparent;
  color: var(--dt-input);
}

.dataTables_wrapper .dataTables_length select {
  font-size: 9pt;
  border: 1px solid var(--dt-input-border);
  border-radius: 3px;
  padding: 5px;
  background-color: transparent;
  color: var(--dt-input);
}

.dataTables_wrapper th select {
  padding: 3px 4px;
  line-height: inherit;
  color: var(--dt-input);
  font-size: 9pt;
  font-family: inherit;
  border: 1px solid var(--dt-input-border);
  border-radius: 3px;
}

.dataTables_wrapper th input {
  padding: 2px 4px;
  line-height: inherit;
  font-size: 9pt;
  font-family: inherit;
  border: 1px solid var(--dt-input-border);
  border-radius: 3px;
  color: var(--dt-input);
}

.dataTables_wrapper .dataTables_scroll {
  padding-top: 5px;
  padding-bottom: 5px;
}

/* DATA TABLES: SORTING INDICATORS */

.sorting_disabled:before,
.sorting_disabled:after {
  display: none !important;
}

/* DATA TABLES: CHILD DETAIL ROWS */

td.details-control {
  background: url('../images/details_open.png') no-repeat center center;
  cursor: pointer;
}

tr.details td.details-control {
  background: url('../images/details_close.png') no-repeat center center;
}

.form-check-input:checked {
  background-color: var(--bmw-m-yale-blue);
  border-color: var(--bmw-m-yale-blue);
}

.form-check-input.danger:checked {
  background-color: var(--portal-danger);
  border-color: var(--portal-danger);
}

.form-check-input.portal-check-input:checked {
  background-color: var(--bs-secondary);
  border-color: var(--bs-secondary);
}

.form-checkbox {
  font-size: 1.16667rem;
  font-weight: bold;
  min-width: 80px;
}

.form-checkbox > input {
  margin-right: 0.6rem;
}

/* DATATABLE BUTTONS */

button.dt-button,
input.dt-button,
a.dt-button {
  color: var(--bs-white);
  background: var(--bs-dark);
  border: none !important;
  border-radius: 0;
  padding: 0.66667rem 1.33333rem;
  margin: 0.83333rem 0.16667rem 0 0;
}

button.dt-button:hover:not(.disabled),
input.dt-button:hover:not(.disabled),
a.dt-button:hover:not(.disabled),
button.dt-button:focus:not(.disabled),
input.dt-button:focus:not(.disabled),
a.dt-button:focus:not(.disabled) {
  background: var(--overview-title-color);
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: #fff !important;
  background: var(--bs-dark);
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  background-image: none !important;
}

.btn.table-cell-button,
.table-action-button,
.table-action-button-group > .btn {
  height: 2rem;
  font-size: 1rem;
  border-radius: 1px;
  padding: 0 0.5rem;
}

.table-action-button,
.table-action-button-group > .btn {
  width: 2rem;
  border: 1px solid var(--dt-input-border);
  color: var(--dt-input);
  background-color: var(--bs-white);
  padding: 0;
}

.table-action-button:hover,
.table-action-button-group > .btn:hover {
  background-color: var(--bs-dark);
  color: var(--bs-white);
}

.table-action-button-group > .btn:not(:last-child) {
  margin-right: 0.16667rem;
}

.table-action-button-group > .btn:not(:first-child) {
  margin-left: 0.16667rem;
}

/* DATA TABLES: COLUMN VISIBILITY DROPDOWN */

.table-config-dropdown {
  overflow-y: scroll;
  max-height: 30rem;
}

.table-config-dropdown .dropdown-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.table-config-dropdown input[type='checkbox'] {
  cursor: pointer;
}

.table-config-dropdown label {
  user-select: none;
  cursor: pointer;
}

/* COMPRESSED TABLE */

table.compressed {
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
}

.table-fixed table.compressed {
  table-layout: fixed;
}

table.compressed tbody tr.compressed {
  height: 0.83333rem;
  border-bottom: 1px solid var(--bs-light);
}

table.compressed tbody tr.compressed:hover {
  height: 0.83333rem;
  border-bottom: 1px solid var(--bs-light);
  background-color: var(--light-grey);
}

table.compressed thead th.compressed {
  margin: 0;
  border-bottom: 1px solid var(--bs-dark);
  border-top: 0;
}

table.compressed thead th,
table.compressed tbody tr td {
  padding: 0.25rem 1.66667rem 0.25rem 0;
  margin: 0;
  border: 0;
  width: 0.1rem;
  white-space: nowrap;
  overflow: hidden;
}

table.compressed thead th,
table.compressed tbody tr td.name {
  vertical-align: middle;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
}

table.compressed tbody tr td.name.small-width {
  width: 1%;
}

table.compressed thead th,
table.compressed tbody tr td.value {
  vertical-align: middle;
  white-space: normal;
  overflow: visible;
}

table.compressed tbody tr td.value.small-width {
  width: 1%;
}

table.compressed thead th.index,
table.compressed tbody tr td.index {
  color: var(--overview-title-color);
  width: 0.1rem;
  white-space: nowrap;
  overflow: hidden;
}

/* TABLE HELPER BUTTONS */

.helperBtn {
  margin-left: 0.33333rem;
  opacity: 0;
  color: black;
  cursor: pointer;
}

.detailsContainer {
  white-space: pre-line;
}

td:hover > .helperInlineLink {
  text-decoration: none;
}

td > .helperInlineLink > .helperBtn {
  margin-left: 0;
}

td > .helperBtn:nth-child(1) {
  margin-left: 0.6rem;
}

td:hover > .helperBtn,
td:hover > a > .helperBtn {
  transition: none;
  opacity: 0.2;
}

td:hover > .helperBtn:hover,
td:hover > a > .helperBtn:hover {
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
  pointer-events: painted;
  color: black;
}

td:hover > .helperBtn:active,
td:hover > a > .helperBtn:active {
  color: var(--bs-success);
  transition: color 0s;
}

/* DATA TABLES: COLLAPSIBLE CHILD DETAIL ROWS */

.info-table-container {
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.info-table-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

table.dataTable tbody tr.rowDetails {
  background-color: var(--dt-row-details);
}

table.table-hover tbody tr.rowDetails td:not(.detailsContainer),
table.table-hover tr.rowDetails:hover {
  /* don't highlight child rows on hover */
  --bs-table-accent-bg: transparent;
}

table.dataTable tbody tr.rowDetails > td {
  padding: 1.66667rem;
}

.detailsTab {
  background-color: var(--bs-white);
  border: 1px solid var(--light-grey);
  border-top-color: transparent;
}

.detailsContent {
  margin: 0.41667rem 0 1.25rem 0;
  padding: 1.25rem 1.66667rem 0.41667rem 1.66667rem;
  overflow-x: hidden;
}

/* CARDS */

.card-header:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.infobox {
  margin-bottom: 1.5rem;
  background: var(--portal-info-background);
}

.infobox p:last-child {
  margin-bottom: 0;
}

/* FORMS */

#form-error > ul,
#form-success > ul {
  margin-bottom: 0;
}

/* .was-validated .dropdown.bootstrap-select.form-control:has(select:invalid) {
  margin-bottom: 2rem;
} */

.needs-validation .bootstrap-select.form-select {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
  border: 1px solid var(--navbar-light);
  border-right: 1px solid var(--navbar-light);
}

.was-validated .form-group:not(:last-child):has(select:invalid) {
  margin-bottom: 0rem;
}

.was-validated .col.input-group:has(select:invalid) {
  margin-bottom: 2rem;
}

.was-validated .bootstrap-select select:invalid + .dropdown-toggle:after {
  content: 'You must select an option';
  font-size: 1rem;
  opacity: 1;
  width: auto;
  color: #dc3545;
  border: none;
  position: absolute;
  right: 100%;
  transform: translate(100%, 0.25rem);
  top: 100%;
}

.form-card {
  margin-bottom: 2rem;
  border-color: var(--bs-dark);
}

.form-card > .card-body {
  padding: 2rem;
}

.form-group:not(:last-child) {
  margin-bottom: 1rem;
}

.card-header {
  background-color: #edf6ff;
}

.form-card > .card-header {
  background-color: var(--bs-dark);
  border-color: var(--bs-dark);
  color: var(--bs-white);
}

.form-steps-container {
  padding: 1.5rem 0 3rem;
}

.form-step {
  display: none;
}

.form-step.current-step {
  display: block;
}

.form-step-separator {
  position: relative;
  display: block;
  height: 1px;
  margin: 1rem 0 2.6rem;
}
.form-step-separator:not(:first-of-type) {
  margin: 4rem 0 2.6rem;
}

.form-step-separator:before {
  content: '';
  position: absolute;
  display: block;
  width: 98%;
  height: 1px;
  background-color: var(--bs-secondary);
  left: 50%;
  transform: translateX(-50%);
}

.form-step-separator:after {
  content: attr(data-label);
  position: absolute;
  display: block;
  background-color: white;
  color: var(--bs-secondary);
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 0 1rem;
}

.form-result-container {
  position: relative;
}
.form-result {
  border-radius: 6px;
  border: 1px solid var(--bs-secondary);
  background-color: rgba(150, 150, 150, 0.1);
  padding: 0.7rem 1.8rem 0.7rem 0.8rem;
  width: 100%;
  height: 3.1rem;
}
.form-result--success {
  color: var(--bs-success);
  border: 1px solid var(--bs-success);
  background-color: rgb(0, 150, 136, 0.1);
}
.form-result--error {
  color: tomato;
  border: 1px solid tomato;
  background-color: rgb(255, 99, 71, 0.1);
}

.form-result-container .copy-icon {
  position: absolute;
  top: 0.6rem;
  right: 0.8rem;
  border-radius: 2px;
  padding: 0.2rem;
  cursor: pointer;
}
.form-result-container .copy-icon > i {
  position: relative;
  pointer-events: none;
}

.form-result-container .copy-icon.copied > i:after {
  position: absolute;
  content: 'copied!';
  top: 50%;
  left: calc(120% + 1.2rem);
  transform: translateY(-50%);
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
}

.form-control-overlay {
  position: absolute;
  top: 0;
  width: calc(100% - 50px);
  border: 1px solid transparent;
  background-color: transparent;
  padding: 0.583rem 0.75rem;
  font-size: 1.1667rem;
  white-space: nowrap;
  pointer-events: none;
}

.form-control.bootstrap-select {
  border: 1px solid var(--navbar-light);
  background-color: transparent;
}

.form-control.bootstrap-select > .dropdown-toggle:after {
  opacity: 0;
}

.form-control.bootstrap-select > .dropdown-toggle:focus,
.form-control.bootstrap-select > .dropdown-toggle:hover {
  background-color: rgba(192, 194, 196, 0.1);
}

.form-control--transition {
  transition: all 0.15s ease-in-out;
}

.form-control:disabled,
.form-control[readonly],
select[disabled] > option,
.btn-light:disabled,
.btn-light.disabled,
.form-control.bootstrap-select.disabled,
.form-control.bootstrap-select.disabled + button {
  background-color: #e9ecef !important;
  color: var(--bs-dark) !important;
  cursor: not-allowed;
  font-style: italic;
  pointer-events: none;
}

.cursor-help {
  cursor: help;
}

.custom-tooltip {
  --bs-tooltip-bg: var(--bs-dark);
  --bs-tooltip-color: white;
  font-size: 10pt;
}

.custom-tooltip-icon {
  color: var(--bs-gray);
  margin-left: 5px;
  font-size: 1.1rem;
}

.portal-dropdown .bootstrap-select {
  border: 1px solid var(--navbar-light);
  border-right: 1px solid transparent;
}

.portal-dropdown.no-buttons .bootstrap-select {
  border: 1px solid var(--navbar-light);
}

.vpc-dropdown .dropdown-menu li.disabled:not(:nth-child(1)) {
  cursor: not-allowed;
}

.vpc-dropdown .dropdown-menu li.disabled:not(:nth-child(1)) a.dropdown-item {
  background-color: #f0f0f0;
}

button.portal-selectpicker-mid,
button.portal-selectpicker-end {
  background-color: transparent;
}

button.portal-selectpicker-mid:hover,
button.portal-selectpicker-end:hover {
  background-color: var(--bs-light-grey);
}

button.portal-selectpicker-mid {
  border: 1px solid var(--navbar-light);
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}

button.portal-selectpicker-end {
  border: 1px solid var(--navbar-light);
  border-left: 1px solid transparent;
}

div.dropdown button,
div.dropdown button .filter-option,
div.dropdown button .filter-option-inner,
div.dropdown button .filter-option-inner-inner {
  background-color: transparent;
  height: 100%;
  display: flex;
  align-items: center;
}

.filter-option-inner-inner > .text-muted {
  margin-left: 5px;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
  background-color: var(--overview-title-color);
  color: var(--bs-white);
}

input.form-control[type='date'] {
  padding-top: 0.463rem;
  padding-bottom: 0.463rem;
}

input.form-control[type='time'],
input.form-control[type='text'],
input.form-control[type='number'],
select.form-select {
  padding-top: 0.583rem;
  padding-bottom: 0.583rem;
}

.form-control:not(:placeholder-shown):not([type='date']):invalid {
  border: 1px solid red;
}

.bs-dropdown-item-text {
  margin: 0 8px 0 2px;
}

.filter-option-inner-inner > .bs-dropdown-badge {
  padding: 0 5px;
  margin-right: 6px;
}

.bs-dropdown-badge {
  color: #000;
  font-family: monospace;
  border-radius: 2px;
  background-color: var(--light-grey);
  padding: 1px 5px;
  margin-right: 3px;
  white-space: nowrap;
}

.bs-dropdown-badge.new {
  box-sizing: border-box;
  border: 1px solid var(--bs-gray);
  color: #000;
  background-color: #fff;
}

.bs-dropdown-badge.enabled,
.bs-dropdown-badge.created,
.bs-dropdown-badge.approved,
.bs-dropdown-badge.active {
  color: #fff;
  background-color: var(--bs-success);
}

.bs-dropdown-badge.deleted,
.bs-dropdown-badge.declined {
  color: #fff;
  background-color: var(--bs-dark);
}

.bs-dropdown-badge.failed,
.bs-dropdown-badge.danger {
  color: #fff;
  background-color: var(--bs-danger);
}

.bs-dropdown-badge.account,
.bs-dropdown-badge.cidr {
  color: #fff;
  background-color: var(--bs-blue);
}

.bs-dropdown-badge.warning {
  color: #000;
  background-color: var(--bs-warning);
}

.bs-dropdown-badge.bold-text {
  font-family: unset;
  font-size: 0.6rem;
  font-weight: bold;
  padding: calc(0.2rem + 2px) 0.4rem;
  margin: 0 0.4rem;
  letter-spacing: 2px;
}

.bs-dropdown-badge.text-overflow {
  display: inline-block;
  max-width: 75px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  padding: 0 5px;
}

.was-validated .form-textarea:has(textarea:invalid) {
  margin-bottom: 2.4rem;
}

textarea:invalid + .invalid-feedback {
  position: absolute;
  top: 100%;
}

.form-textarea-char-counter {
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 0.8rem;
  letter-spacing: 1px;
  padding: 0.05rem 0.3rem;
  border-radius: 6px;
  background-color: #ededed;
}
.form-textarea-char-counter > .char-count {
  font-weight: bold;
}

.form-textarea-tooltip-padding {
  padding-right: 2.4rem;
}

.form-inline-label-text {
  padding: 0.5rem 0.6rem;
  line-height: 2;
  font-weight: bold;
}

.form-label-text {
  padding: 0.7rem;
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 1.16667rem;
  font-weight: bold;
  text-align: right;
}

.form-value-text {
  padding: 0.7rem;
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 1.16667rem;
  color: var(--bs-gray);
}

.form-description-text {
  position: relative;
  padding: 0.3rem 0.4rem 0.3rem 1.4rem;
  font-size: 0.8rem;
  letter-spacing: 0.05rem;
  color: var(--bs-secondary);
}
.form-description-text:not(:empty):before {
  content: '';
  position: absolute;
  height: 5px;
  width: 5px;
  top: 8px;
  left: 4px;
  background-color: var(--bs-primary);
}

.col-form-label,
.form-control,
.form-select {
  font-size: 1.16667rem;
}

.col-form-label {
  flex: 0 0 auto;
  font-weight: bold;
}

/* Extra small devices (phones, up to 767px) */
@media screen and (max-width: 991px) {
  .col-form-label {
    width: 100%;
  }

  .form-result-container .copy-icon.copied > i:after {
    position: absolute;
    content: 'copied!';
    top: unset;
    bottom: 150%;
    left: unset;
    right: 0;
    transform: translate(20%, -100%);
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
  }
}

@media screen and (min-width: 992px) {
  .col-form-label {
    text-align: right;
  }
}

/* Tablets/desktops and up */
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .col-form-label {
    width: 25%;
  }

  /* If the form is split in two columns then we need to allot more space to the labels */
  .row-cols-lg-2 > .col > .row > .col-form-label {
    width: 40%;
  }

  .card-body > .row > .col-form-label {
    width: 40%;
  }
}

@media screen and (min-width: 1200px) {
  .col-form-label {
    width: 16.667%;
  }

  /* If the form is split in two columns then we need to allot more space to the labels */
  .row-cols-xl-2 > .col > .row > .col-form-label {
    width: 33.333%;
  }
}

/* FORM VALIDATION */

.input-group.is-invalid {
  border: 1px solid var(--portal-danger);
  /* border: 1px solid #dc3545; */
  border-radius: 0.25rem;
  box-shadow: 0 0 0 0.25rem #dc354540;
}

.input-group.is-invalid :focus {
  box-shadow: 0 0 0 0.25rem #dc354540;
}

.input-group-text {
  font-size: 1.16667rem;
}

/* INLINE CHECKBOXES */

.checkbox-inline {
  display: inline-flex;
  gap: 0.5rem;
}

.checkbox-inline > input[type='checkbox'] {
  margin-top: 0.1em;
}

.form-group > .col > label.checkbox-inline {
  font-size: 1.16667rem;
}

.form-group > .col > label.checkbox-inline > input[type='checkbox'] {
  margin-top: 0.3em;
}

/* CODE BOXES */

pre.add-line-numbers {
  background-color: var(--dt-row-details);
  color: var(--bs-black);
  padding: 0;
  border-radius: 3px;
  text-align: left;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

pre.add-line-numbers span:first-child:before {
  padding-top: 4px;
}

pre.add-line-numbers span:last-child:before {
  padding-bottom: 4px;
}

pre.add-line-numbers .line-numbers-row {
  display: block;
  font-size: 1rem;
  line-height: 1.5rem;
  counter-increment: line;
  padding-left: 3.5rem;
  position: relative;
  max-width: 110vw;
  height: auto;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

pre.add-line-numbers .line-numbers-row:before {
  content: counter(line);
  display: inline-block;
  border-right: 1px solid #c0c0c0;
  padding: 0 0.5em;
  margin-right: 0.6em;
  min-width: 35px;
  background-color: #eaeaea;
  text-align: right;
  font-size: 1rem;
  line-height: 1.5rem;
  height: 100%;
  color: var(--bs-secondary);
  position: absolute;
  left: 0;
}

.test-output-field pre.add-line-numbers {
  max-height: 350px;
  overflow-y: scroll;
}

pre {
  background-color: var(--dt-row-details);
  margin: 0 0 0em;
  padding: 0.5em 1em;
  border: 0.5px solid var(--bs-light);
  border-radius: 0;
}

pre.add-line-numbers code,
pre .line-number {
  font: normal normal 1rem/1.16667rem 'Courier New', 'Courier', monospace;
  color: var(--bs-black);
  display: block;
}

pre .line-number {
  float: left;
  margin: 0 1em 0 -1em;
  border-right: 1px solid;
  text-align: right;
}

pre .line-number span {
  display: block;
  padding: 0 0.5em 0 1em;
}

pre .cl {
  display: block;
  clear: both;
}

div.cli-config {
  padding: 1% 1%;
}

code.cli-config {
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
}

code.cli-config.linebreak {
  white-space: normal;
  word-break: keep-all;
}

code.cli-config p {
  margin-top: 0;
  margin-bottom: 0;
}

/* DASHBOARD / FOURWHEELS PAGES */

.cloudcount,
.fourwheelscount {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--bs-dark);
  font-size: 3.33rem;
  text-overflow: ellipsis;
  padding-left: 1.25rem;
}

.cloudcount .fas {
  font-size: 5rem;
}

/* FOURWHEELS PAGE */

.fourwheelscount img.kubernetes {
  width: 3.333rem;
}

/* DIRECT CONNECT DETAILS AREA */

div.statsOverview {
  margin: 0;
  padding: 0 0 1.66667rem;
  overflow: hidden;
}

div.statsContainer {
  overflow: hidden;
}

div.statsCard {
  position: relative;
  display: inline;
  flex-direction: column;
  min-width: 0;
  background-clip: border-box;
}

div.statsCardBody {
  flex: 1 1 auto;
  min-height: 0.1rem;
  white-space: nowrap;
  word-wrap: break-word;
  font-family: 'Arial', sans-serif;
  overflow: hidden;
  padding: 0.83333rem;
  margin: 0.41667rem;
}

div.statsCardBody.warning {
  padding: 0.66667rem;
  border: 2px solid var(--portal-warning);
}

div.statsCardBody.danger {
  padding: 0.66667rem;
  border: 2px solid var(--portal-danger);
}

div.statsCardBody h5 {
  margin: 0.16667rem 0.16667rem 0.41667rem;
  font-weight: bold;
  color: var(--overview-title-color);
  text-transform: uppercase;
}

div.statsCardBody h4 {
  margin: 0;
}

div.statsCardInfo {
  position: relative;
  float: left;
  width: 60%;
  min-height: 0.1rem;
  text-align: right;
  direction: rtl;
  padding-right: 1.25rem;
  padding-left: 0;
  margin: 0;
}

div.statsCardInfoContent {
  width: 100%;
}

div.statsCardContent {
  position: relative;
  float: left;
  width: 40%;
  min-height: 0.1rem;
  padding-right: 0;
  padding-left: 1.25rem;
  text-align: left;
  margin: 0;
  border-left: 1px solid var(--overview-title-color);
}

div.statsContentValue {
  margin: 0;
  padding: 0;
  font-size: 20pt;
  line-height: 110%;
}

div.statsContentValue.danger {
  color: var(--portal-danger);
  font-weight: bold;
}

div.statsContentValue.warning {
  color: var(--portal-warning);
  font-weight: bold;
}

div.statsContentValue small {
  font-size: 60%;
  font-weight: lighter;
}

/* VPC PAGE */

.hint {
  color: var(--bs-grey);
  font-style: italic;
}

/* COMPARE RESOURCES PAGE */

.compare-dropdown-container {
  padding: 0.5rem 0.1rem;
}

.compare-dropdown-container button.actions-btn.btn.btn-light {
  font-weight: bold;
  padding: 0.3rem 0.5rem;
  margin-top: 0.5rem;
}

.compare-dropdown-container button.actions-btn.btn.btn-light.bs-select-all {
  border-radius: 5px 0 0 5px;
  color: var(--bs-green);
  background-color: rgba(25, 135, 84, 0.15);
}

.compare-dropdown-container button.actions-btn.btn.btn-light.bs-select-all:hover {
  color: var(--bs-white);
  background-color: var(--bs-green);
}

.compare-dropdown-container button.actions-btn.btn.btn-light.bs-deselect-all {
  border-radius: 0 5px 5px 0;
}

.compare-content {
  height: 100%;
  margin: 1rem 0 0;
}

.compare-section-container > div.row {
  border: 1px solid #ededed;
  border-radius: 3px;
}

.section-container {
  position: relative;
  border-top: 2px solid grey;
  margin: 2.5rem 0 1rem;
}

.section-container:after {
  content: attr(data-section);
  position: absolute;
  left: 4px;
  top: -1.3rem;
  font-size: 1.1rem;
  line-height: 1;
}

.compare-resources-equal {
  opacity: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -14px);
  transition: all 0.2s ease-in-out;
}

.compare-resources-equal.is-equal {
  opacity: 1;
}

.compare-content:not(.disable-highlighting) tr.compressed.compare-highlight,
.compare-content:not(.disable-highlighting) td.compare-highlight {
  color: var(--bs-green);
  font-weight: bold;
  background-color: rgba(25, 135, 84, 0.07);
}

/* hide scrollbar */
.compare-overflow-content {
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.compare-overflow-content::-webkit-scrollbar {
  display: none;
}

/* RELEASE NOTES */

#releasenotes h4 small {
  font-weight: 400;
  line-height: 1;
  color: var(--bs-secondary);
  font-size: 75%;
}

#releasenotes h5 {
  font-size: 1.16667rem;
}

.divider {
  width: 1px;
  height: 3rem;
  margin: 0 1.5rem;
  opacity: 0.6;
}

.w-60 {
  width: 60% !important;
}

.w-35 {
  width: 35% !important;
}

.gap-10 {
  gap: 10px;
}

.fz-09 {
  font-size: 0.9rem;
}

.flex-input-group {
  display: flex;
  width: 83%;
  justify-content: space-between;
  gap: 10px;
}

.portal-dropdown .input-group {
  max-height: 35px;
  margin: auto 0;
}

/* Extra small devices (phones, up to 767px) */
@media screen and (max-width: 767px) {
  .w-60 {
    width: 0;
  }

  .w-35 {
    width: 0;
  }

  .gap-10 {
    gap: 0;
  }
}

.input-group input.form-control {
  line-height: 1.7;
}

.swagger-ui .info .title small pre {
  background-color: transparent;
  border: 0px;
  padding: 5px;
}

.swagger-ui code[class*='language-'],
pre[class*='language-'] {
  text-shadow: unset;
  color: unset;
}

.accordion-button:not(.collapsed) {
  background-color: var(--portal-info-background);
}

.accordion-button {
  background-color: var(--portal-info-background);
}

.accordion {
  --bs-accordion-active-color: #000000;
}

/* The following settings are used for the vpc/account details modal and the (temp) role information at the bottom */
div.role-container {
  padding-top: var(--bs-modal-padding);
}

.tile-metric-container {
  min-height: 170px;
  margin-bottom: 3rem;
}

.tile-metric-bar-chart {
  position: relative;
  display: flex;
  flex-direction: row;
}

.tile-metric-bar {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 0.5rem;
}

.tile-metric-bar-content {
  display: block;
  height: 100%;
  position: relative;
}

.tile-metric-bar-prediction {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: gray;
}

h4.tile-metric-title {
  min-height: 2rem;
  margin-bottom: 2rem;
}

.tile-metric-bar-diff {
  position: relative;
  flex: 1;
  border: 1px solid;
  border-width: 0 1px 0 0;
  margin: 0 50%;
}

.tile-metric-bar-diff:before {
  content: '';
  position: absolute;
  top: 0;
  width: 0.4rem;
  height: 1px;
  background-color: currentColor;
}

.tile-metric-bar-diff:after {
  content: attr(data-text);
  position: absolute;
  top: -1.5rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1rem;
}

.tile-metric-bar-diff--increase:before {
  right: 50%;
}

.tile-metric-bar-diff--decrease:before {
  left: 50%;
}

.tile-text-primary {
  font-size: 2.675rem;
  line-height: 3rem;
  margin-right: 1rem;
}

.tile-text-secondary {
  color: rgb(107 114 128);
  font-size: 1.375rem;
  line-height: 1.95rem;
}

.tile-text-tertiary {
  color: rgb(107 114 128);
  font-size: 1.075rem;
  line-height: 1.55rem;
}

.tile-badge {
  border-radius: 4px;
  background-color: rgba(150, 150, 150, 0.3);
  font-size: 1rem;
  font-weight: bold;
  color: rgb(120, 120, 120);
  padding: 0.3rem 0.6rem;
}

.tile-badge--red {
  background-color: rgba(255, 99, 71, 0.3);
  color: rgb(255, 99, 71);
}

.tile-badge--green {
  background-color: rgba(55, 175, 55, 0.3);
  color: rgb(55, 175, 25);
}

.tile-badge--yellow {
  background-color: rgba(255, 193, 7, 0.3);
  color: rgb(255, 193, 7);
}

.tile-label--red {
  color: rgb(255, 99, 71);
}

.tile-label--green {
  color: rgb(55, 175, 25);
}

.tile-label--yellow {
  color: rgb(255, 193, 7);
}

.tile-top-right {
  position: absolute;
  top: 1rem;
  right: 1.6rem;
}

.tile-chart-bar {
  display: flex;
  flex-direction: row;
}

.tile-chart-bar-background {
  position: relative;
  flex: 1;
  margin: 0.4rem 0;
  height: 0.6rem;
  border-radius: 0.3rem;
}

.tile-chart-bar-body {
  display: inline-block;
  height: 0.6rem;
  border-radius: 0.3rem;
  vertical-align: top;
}

.tile-chart-bar-value {
  padding: 0.1rem 0.2rem 0.1rem 0.9rem;
  color: #a0a0a0;
  font-size: 0.8rem;
}

.tile-chart-bar-ticks {
  position: absolute;
  color: #a0a0a0;
  font-size: 1rem;
  line-height: 1.25rem;
  transform: translateX(-50%);
}

.tile-chart-bar-ticks:first-child {
  transform: translateX(0);
}
.tile-chart-bar-ticks:last-child {
  transform: translateX(-100%);
}

.tile-chart-legend {
  padding-bottom: 0.6rem;
}

.tile-chart-legend-entry {
  display: inline-block;
  position: relative;
  padding: 0.2rem 0.8rem 0.2rem 1.4rem;
}

.tile-chart-legend-entry > span {
  color: black;
}
.tile-chart-legend-entry.tile-chart-legend-entry--large > span {
  font-size: 1.2rem;
  line-height: 1.2rem;
  margin-left: 0.4rem;
}

.tile-chart-legend-entry:before {
  position: absolute;
  content: '';
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  top: 50%;
  left: 0.1rem;
  transform: translateY(-50%);
  background-color: currentColor;
}

.tile-chart-legend-entry.tile-chart-legend-entry--large:before {
  width: 1.2rem;
  height: 1.2rem;
  left: 0.1rem;
}

.tile-vertical-bar {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 250px;
  max-width: 80px;
  text-align: center;
}

.tile-vertical-bar > .label {
  padding: 0.2rem 0;
}

.tile-vertical-bar > .bar {
  width: 80%;
  height: 80%;
  background-color: var(--bs-success);
  margin: 0 auto;
  align-self: flex-end;
  border-radius: 3px 3px 0 0;
}

.tile-vertical-bar:not(.hide-labels):after {
  position: absolute;
  top: calc(100% + 0.4rem);
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  content: attr(data-label);
  padding: 0 0.2rem;
}

.tile-chart-axes {
  border-top: 1px solid #c0c0c0;
  height: 1.5rem;
}

.tile-tooltip {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  border-radius: 3px;
  padding: 0.4rem 0.6rem;
  background: gray;
  color: white;
  transition: all 100ms ease-in-out;
}

.tile-content > .graph-paper > .tile-vertical-bar:hover ~ .tile-tooltip {
  display: block;
}

/* Y Axis */
/* .tile-chart-axes:before {
  content: '';
  display: block;
  border-bottom: 1px solid #c0c0c0;
  width: calc(250px + 1rem);
  transform-origin: left top;
  transform: translate(0.5rem, 0.5rem) rotateZ(-90deg);
} */

/* Quad-Ruled Background */
/* .graph-paper {
  background-image: linear-gradient(#cdcdcd 1.5px, transparent 1.5px), linear-gradient(90deg, #cdcdcd 1.5px, transparent 1.5px), linear-gradient(#ededed 1px, transparent 1px), linear-gradient(90deg, #ededed 1px, transparent 1px);
  background-position: -1.5px -1.5px, -1.5px -1.5px, -1px -1px, -1px -1px;
  background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
} */

.graph-paper {
  background-image: linear-gradient(#ededed 1px, transparent 1px);
  background-position: -1px -1px;
  background-size: 50px 50px;
}

.card-body .tile-expand-button {
  display: none;
}

.card-body.card-body--collapsed .tile-expand-button {
  display: block;
  position: absolute;
  bottom: 0.6rem;
  right: 1.8rem;
  font-size: 2rem;
  color: #303030;
}

.card-body {
  position: relative;
}

.card-body--collapsed .tile-content {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.card-body:not(.card-body--collapsed) > .tile-content-overflow {
  max-height: 100%;
}

.card-body--collapsed .tile-content > .tile-content-overflow {
  max-height: 0;
  overflow: visible;
}

.card-body--collapsed .tile-content:after {
  background: linear-gradient(180deg, transparent, transparent, white);
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.card-select-field {
  position: absolute;
  top: 0;
  right: 0.8rem;
  width: auto;
}

/* Avoid huge modals for people with many roles. With 200px we can see 5 records without scrollbar */
table.roles-table {
  display: block;
  max-height: 200px;
  overflow-y: auto;
}

/* With this configuration we can make the different rows somewhat evenly sized (rows with and without button) */
table.roles-table tbody tr.compressed,
table.roles-table tbody tr.compressed:hover {
  height: 35px;
}

/* Shrink the size of the buttons a bit to avoid huge rows */
table.roles-table .btn-sm {
  padding: 5px 15px 5px 15px;
}

.process-steps-container {
  display: flex;
  height: 80px;
  flex-direction: row;
  position: relative;
}

.process-step {
  display: block;
  flex: 1;
  position: relative;
}

.process-step > p {
  text-align: center;
}

.process-step.current-step > p {
  color: green;
  font-weight: bold;
}

.process-step:after {
  content: '';
  width: 2rem;
  height: 2rem;
  background: white;
  border: 2px solid gray;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: 1;
}

.process-step:not(:first-child):before {
  content: '';
  position: absolute;
  width: 100%;
  height: 5px;
  top: 50%;
  left: -50%;
  background: gray;
  transform: translateY(-50%);
}

.process-step.process-step--fill:not(:first-child):before {
  background: green;
}

.process-step--fill:after {
  background: green;
  border: 2px solid green;
}

.current-step:after {
  width: 2.7rem;
  height: 2.7rem;
}

.create-subscription-filters-container {
  display: none;
}

.datasets-container {
  overflow-x: auto;
}

.datasets-checkbox-header,
.datasets-checkbox {
  width: 8rem;
}

.datasets-header {
  background: #f9f9f9;
  font-weight: bold;
  padding: 0.5rem 1.2rem;
  border-bottom: 1px solid gray;
}

.datasets-header:not(:last-child) {
  border-right: 1px solid gray;
}

.datasets-row {
  border-bottom: 1px solid #c0c0c0;
}

.datasets-row > td {
  padding: 0.5rem 1.2rem;
}

.datasets-row > td:not(:last-child) {
  border-right: 1px solid #c0c0c0;
}

.datasets-row.selected-row {
  background: #cfe2ff;
}

.datasets-checkbox-header,
.datasets-checkbox {
  text-align: center;
  cursor: pointer;
}

.datasets-checkbox > input {
  transform: translateY(2px);
  pointer-events: none;
}

.datasets-search {
  max-width: 300px;
}

.datasets-label-text {
  margin: 0.3rem auto;
  font-size: 1.2rem;
  line-height: 29px;
}

.datasets-pagination .page-link {
  cursor: pointer;
}

#terminal-container {
  position: fixed;
  display: none;
  right: 2.5rem;
  padding: 0.5rem 0.8rem;
  border-radius: 4px;
  background-color: #313131;
  color: white;
  height: 32vh;
  width: 360px;
  min-width: 360px;
  font-family: monospace;
  z-index: 10;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  overflow-y: auto;
}
